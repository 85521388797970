<template>
  <div class="bank">
    <van-nav-bar :title="pageTitle" left-arrow @click-left="goBack" :border="false">
      <template #right>
        <van-icon name="add-o" size="24" @click="goToAccountForm" />
      </template>
    </van-nav-bar>
    <!-- <van-tabs v-model:active="tabActive">
      <van-tab :title="$t('tabTitle.recharge')"></van-tab>
      <van-tab :title="$t('tabTitle.withdrawal')"></van-tab>
    </van-tabs> -->
    <div class="data-list">
      <van-pull-refresh v-model="refreshing" @refresh="refreshData">
        <div>
          <van-list v-if="dataList.length >0">
            <div class="bankcard-block" v-for="item in dataList" :key="item.id">
              <div class="bankcard-header">{{item.bankName}}</div>
              <div class="bankcard-number">{{item.bankCardNo}}</div>
              <div class="bankcard-holder">{{item.bankCardholder}}</div>
              <div v-if="item.ifsc" class="bankcard-holder">ifsc: {{item.ifsc}}</div>
            </div>
          </van-list>
          <div v-else>
            <van-empty :description="$t('common.noData')" />
          </div>
        </div>
      </van-pull-refresh>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import i18n from '@/assets/lang/index';
import { queryRechargeBankcardList, queryWithdrawalBankcardList, deleteRechargeBankcard } from "@/api";


export default {
  setup() {
    const router = useRouter();
    const goBack = () => router.back();
    
    const tabActive = ref(1);
    const refreshing = ref(false);
    return {
      goBack,
      tabActive,
      refreshing,
    };
  },
    
  data() {
    return {
      pageTitle: localStorage.getItem('pageName'),
      dataList: [],
    };
  },
  
  watch: {
    tabActive() {
      this.refreshing = true;
      this.refreshData();
    }
  },
  
  mounted() {
    this.fetchDataList();
  },

  methods: {
    fetchDataList() {
      if (this.refreshing) {
        this.refreshing = false;
      }
      const doPost = this.tabActive ? queryWithdrawalBankcardList : queryRechargeBankcardList;
      doPost({pageSizeStr: 100, pageNumberStr: 1}).then(res => {
        if (this.tabActive) {
          let dataList = [];
          if (res.data) {
            dataList.push(res.data);
          }
          this.dataList = dataList;
        } else {
          this.dataList = res.data || []; 
        }
        
      });
    },
    refreshData() {
      this.fetchDataList();
    },
    goToAccountForm() {
      this.$router.push(`/bank/editAccount/${this.tabActive ? 'withdrawal' : 'recharge' }`);
    },
  },
};
</script>

<style lang="less">
.bank {
  padding-bottom: 50px;
  .data-list {
    margin: 16px;
    .bankcard-block {
      padding: 12px;
      margin-bottom: 10px;
      color: #fff;
      border-radius: 6px;
      background: linear-gradient(90deg, #848283 0%, #2F3233 100%);
      .bankcard-header {
        position: relative;
        line-height: 19px;
        padding: 2px 24px 10px 0;
        font-size: 16px;
        font-weight: 600;
        border-bottom: 1px dashed rgba(255, 255, 255, 0.4);
      }
      .bankcard-number {
        padding: 12px 0 8px;
        font-size: 16px;
        font-weight: 600;
      }
      .bankcard-holder {
        margin-bottom: 8px;
        line-height: 19px;
        font-size: 16px;
      }
    }
    .upi-block {
      padding: 12px;
      margin-bottom: 10px;
      color: #fff;
      background: linear-gradient(90deg, #848283 0%, #2F3233 100%);
      .upi-account {
        position: relative;
        padding-right: 24px;
        font-size: 16px;
        font-weight: 600;
      }
    }
    .more-btn {
      position: absolute;
      top: 2px;
      right: 2px;
      width: 18px;
    }
  }
  .page-button-wrap {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 16px;
    box-sizing: border-box;
  }
}
</style>
